import "./Priser.scss";
import React, { useState, useEffect } from "react";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
import styled, { keyframes } from "styled-components";
import Navlinks from "../../Components/Navlinks/Navlinks.jsx";

// Keyframes for sliding animation

const slideRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`

  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {

    opacity: 1; 
  }
`;

const ServiceList = styled.div`
  font-family: "NunitoSansRegular", sans-serif;
  line-height: 1.8;
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-bottom: 3rem;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
  animation-delay: 0.4s;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const StyledP = styled.p`
  text-align: center;
  margin: 0 2rem 1rem;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
  animation-delay: 0.4s;
`;

const StyledTitle = styled.h1`
  font-family: "NunitoSansLight", sans-serif;
  text-align: center;
  font-size: 1.9rem;

  border-bottom: 2px solid #333;
  padding-bottom: 40px;
  text-align: center;

  opacity: 0;
  animation: ${({ isLoaded }) => (isLoaded ? slideUp : "none")} 1s ease forwards;
  animation-delay: 0.2s;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 590;
  margin-top: 20px;
  border-bottom: 2px solid #333;
  padding-bottom: 20px;
  text-align: center;
`;

const ServiceItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
`;

const ServiceTitle = styled.div`
  font-size: 16px;
  max-width: 55vw;
`;

const ServicePrice = styled.div`
  font-size: 15px;
  text-align: right;
`;

function Priser() {
  const [isLoaded, setIsLoaded] = useState(false);

  const [userData, setUserData] = useState(null);

  const [takster, setTakster] = useState(null);

  const [filteredTakster, setFilteredTakster] = useState([]);

  ScrollToTop();

  // useEffect(() => {
  //   const fetchUserData = sessionStorage.getItem("userData");
  //   const parsedUserData = JSON.parse(fetchUserData);
  //   setUserData(JSON.parse(parsedUserData.acf.clinicdata));
  //   console.log(
  //     "User data fetched and stored:",
  //     JSON.parse(parsedUserData.acf.clinicdata)
  //   );
  //   const fetchTakster = sessionStorage.getItem("maler");
  //   const parsedTakster = JSON.parse(fetchTakster);
  //   setTakster(JSON.parse(parsedTakster.takster));
  //   console.log(
  //     "Takster fetched and stored:",
  //     JSON.parse(parsedTakster.takster)
  //   );
  //   setIsLoaded(true);
  // }, []);

  useEffect(() => {
    const fetchUserData = sessionStorage.getItem("userData");
    const parsedUserData = JSON.parse(fetchUserData);
    const clinicData = JSON.parse(parsedUserData.acf.clinicdata);
    setUserData(clinicData);
    console.log("User data fetched and stored:", clinicData);

    const fetchTakster = sessionStorage.getItem("maler");
    const parsedTakster = JSON.parse(fetchTakster);
    const taksterData = JSON.parse(parsedTakster.takster);
    setTakster(taksterData);
    console.log("Takster fetched and stored:", taksterData);

    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (!userData || !takster) return;

    const updatedTakster = [];

    if (userData.takster?.fastlegeTakster) {
      updatedTakster.push({
        title: takster.fastlegeTaksterFrikort?.title || "Fastlege (Frikort)",
        services: takster.fastlegeTaksterFrikort?.services || [],
      });

      updatedTakster.push({
        title:
          takster.fastlegeTaksterUFrikort?.title || "Fastlege (Uten frikort)",
        services: takster.fastlegeTaksterUFrikort?.services || [],
      });
    }

    if (userData.takster?.spesialistTakster) {
      updatedTakster.push({
        title:
          takster.spesialistTaksterFrikort?.title || "Spesialist (Frikort)",
        services: takster.spesialistTaksterFrikort?.services || [],
      });

      updatedTakster.push({
        title:
          takster.spesialistTaksterUFrikort?.title ||
          "Spesialist (Uten frikort)",
        services: takster.spesialistTaksterUFrikort?.services || [],
      });
    }

    setFilteredTakster(updatedTakster);
  }, [userData, takster]); // Runs when userData or takster changes

  return (
    <>
      {isLoaded && userData && (
        <>
          <StyledTitle isLoaded={isLoaded} style={{ borderBottom: "none" }}>
            Priser og betaling
          </StyledTitle>
          {/* <StyledP isLoaded={isLoaded}>
            Her finner du alle våre tjenester og priser.
          </StyledP> */}

          {filteredTakster.length > 0 && (
            <ServiceList isLoaded={isLoaded}>
              {filteredTakster.map((section, index) => (
                <div
                  key={index}
                  style={{
                    // background: "#f4f7fe90",
                    background: "rgb(246, 249, 253, .8)",
                    boxShadow: "0 1.2px 3px 0.1px rgba(0, 0, 0, 0.3)",
                    borderRadius: 10,
                    padding: "10px 35px 30px",
                  }}
                >
                  {section.title && (
                    <SectionTitle>{section.title}</SectionTitle>
                  )}
                  {section.services.length > 0 ? (
                    section.services.map((service, index) => (
                      <ServiceItem key={index}>
                        {service.name && (
                          <ServiceTitle>{service.name}</ServiceTitle>
                        )}
                        {service.price && (
                          <ServicePrice>{service.price}</ServicePrice>
                        )}
                      </ServiceItem>
                    ))
                  ) : (
                    <p>Ingen tilgjengelige takster</p>
                  )}
                </div>
              ))}
            </ServiceList>
          )}

          {userData.serviceSections.length > 0 && (
            <ServiceList isLoaded={isLoaded}>
              {userData.serviceSections.map((section, index) => (
                <div
                  key={index}
                  style={{
                    // background: "#f4f7fe90",
                    background: " rgb(246, 249, 253, .8)",
                    boxShadow: "0 1.2px 1.5px 0 rgba(0, 0, 0, 0.3)",
                    borderRadius: 7,
                    padding: "10px 35px 30px",
                  }}
                >
                  {section.title && (
                    <SectionTitle>{section.title}</SectionTitle>
                  )}
                  {section.services.length > 0 && (
                    <>
                      {section.services.map((service, index) => (
                        <ServiceItem key={index}>
                          {service.name && (
                            <ServiceTitle>{service.name}</ServiceTitle>
                          )}
                          {service.price && (
                            <ServicePrice>{service.price}</ServicePrice>
                          )}
                        </ServiceItem>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </ServiceList>
          )}

          {userData.payment.length > 0 && (
            <ServiceList isLoaded={isLoaded}>
              <div
                style={{
                  // background: "#f4f7fe90",
                  background: " rgb(246, 249, 253, .8)",
                  boxShadow: "0 1.2px 1.5px 0 rgba(0, 0, 0, 0.3)",
                  borderRadius: 7,
                  padding: "10px 35px 30px",
                }}
              >
                <SectionTitle>Betaling</SectionTitle>

                {userData.payment.map((payment, index) => (
                  <div key={index}>
                    <h3
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        padding: 0,
                        margin: "2rem 0 0",
                      }}
                    >
                      {payment.title}
                    </h3>
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        fontSize: "16px",
                        lineHeight: "1.8",
                      }}
                    >
                      {payment.text}
                    </pre>
                  </div>
                ))}
              </div>
            </ServiceList>
          )}

          {/* <Navlinks /> */}
        </>
      )}
    </>
  );
}

export default Priser;
