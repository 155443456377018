import "./PageLoader2.css";

export default function PageLoader2() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        transition: "all 0.3s",
        background:
          "linear-gradient(to bottom, #fffffd,rgba(255, 254, 244, 0.2))",
      }}
    >
      <div class="loader">
        <div class="dot dot-1"></div>
        <div class="dot dot-2"></div>
        <div class="dot dot-3"></div>
        <div class="dot dot-4"></div>
      </div>
    </div>
  );
}
