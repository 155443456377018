import "./NyheterCarousel.scss";
import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ArrowLeft from "../../assets/images/arrowLeft.png";
import ArrowRight from "../../assets/images/arrowRight.png";
import CloseBtn from "../../assets/images/close.png";

// Styled components

const StyledH1 = styled.h1`
  font-family: "NunitoSansLight", sans-serif;
  text-align: center;
  font-size: 1.9rem;
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 97%;
  margin: 3rem auto 5rem;

  @media (max-width: 1300px) {
    margin: 3rem 0 5rem;
    width: 100%;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  overflow-x: auto; /* Scrollable with visible scrollbar */
  scroll-behavior: smooth;
  gap: 30px; /* Spacing between articles */
  width: 87%;
  margin: 0 auto;
  padding: 10px; /* Padding inside the scrolling area */
  padding-bottom: 40px; /* Extra padding at the bottom */
`;

const Article = styled.div`
  flex: 0 0 380px; /* Fixed width for each article */
  width: 380px;
  height: 215px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 10px;
  background: rgb(246, 249, 253, 0.8);
  background: rgb(244, 247, 254, 0.314);
  // border: 1px solid #ddd;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 1.2px 3px 0.2px rgba(0, 0, 0, 0.3);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 3.5px 9px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    flex: 0 0 200px;
    width: 200px;
    height: 265px;
  }
`;

const Arrows = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    top: 0;
    width: 85%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledArrow = styled.img`
  width: 30px;
  opacity: ${(props) => props.opacity};
  transition: opacity 0.2s ease-in-out;

  @media (max-width: 768px) {
    width: 20px;
  }
`;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 1200,
  borderRadius: 3,
  height: "auto",
  maxHeight: "80%",
  overflowY: "auto",
  overflowX: "hidden",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  WrapText: "wrap",
};

const StyledCloseBtn = styled.img`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  width: 20px;
  padding: 10px;
  cursor: pointer;
  z-index: 999999999;

  @media (max-width: 399px) {
    right: 0.5rem;
  }
`;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

// Carousel Component
function NyheterCarousel({ news }) {
  const carouselRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const updateScrollButtons = () => {
    const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(Math.ceil(scrollLeft + clientWidth) < scrollWidth);
  };

  const handleScroll = (direction) => {
    let scrollAmount = 0;
    if (windowDimensions.width > 768) {
      scrollAmount = 310;
    } else {
      scrollAmount = 260;
    }
    if (direction === "left") {
      carouselRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    } else if (direction === "right") {
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    updateScrollButtons();
    carousel.addEventListener("scroll", updateScrollButtons);
    return () => carousel.removeEventListener("scroll", updateScrollButtons);
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    setOpenModal(true);
  };

  return (
    <>
      {/* <StyledH1>Nyheter</StyledH1> */}
      <StyledH1>Nyheter</StyledH1>
      <CarouselContainer>
        <Arrows>
          <ArrowButton
            onClick={() => handleScroll("left")}
            disabled={!canScrollLeft}
          >
            <StyledArrow
              opacity={canScrollLeft ? 0.3 : 0}
              src={ArrowLeft}
              alt="Left arrow"
            />
          </ArrowButton>
          <ArrowButton
            onClick={() => handleScroll("right")}
            disabled={!canScrollRight}
          >
            <StyledArrow
              opacity={canScrollRight ? 0.3 : 0}
              src={ArrowRight}
              alt="Right arrow"
            />
          </ArrowButton>
        </Arrows>
        <CarouselWrapper ref={carouselRef}>
          {news
            // .filter((article) => !article.important)
            .slice()
            .reverse()
            .map((article, index) => (
              <Article key={index} onClick={() => handleArticleClick(article)}>
                <h3 className="title">{article.title}</h3>
                <pre className="content">{article.text}</pre>
                {/* <p
                  style={{
                    margin: 0,
                    fontSize: "1.2rem",
                    color: "#00000080",
                    fontWeight: 600,
                    textAlign: "left",
                  }}
                >
                  Les mer..
                </p> */}
              </Article>
            ))}

          {/* {news
            .slice()
            .reverse()
            .map((article, index) => (
              <Article key={index} onClick={() => handleArticleClick(article)}>
                <h3 className="title">{article.title}</h3>
                <p className="content">{article.text}</p>
              </Article>
            ))} */}
        </CarouselWrapper>
      </CarouselContainer>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <StyledCloseBtn
            src={CloseBtn}
            alt="Close button"
            className="close-btn"
            onClick={() => setOpenModal(false)}
          />
          {selectedArticle && (
            <>
              <h2
                style={{
                  marginTop: 0,
                  fontFamily: "NunitoSansRegular",
                  letterSpacing: "2px",
                  maxWidth: "90%",
                }}
              >
                {selectedArticle.title}
              </h2>
              <pre
                style={{
                  fontSize: "1.2rem",
                  fontFamily: "NunitoSansRegular",
                  letterSpacing: "1px",
                  whiteSpace: "pre-wrap",
                }}
              >
                {selectedArticle.text}
              </pre>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default NyheterCarousel;
