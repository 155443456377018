// import Header from "./Header.jsx";
// import Footer from "./Footer.jsx";
// import { Outlet } from "react-router-dom";
// import styled from "styled-components";

// const StyledContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   min-height: 98vh;
// `;

// const StyledContent = styled.div`
//   flex: 1; /* Allow the content area to grow to fill available space */
// `;

// function Layout() {
//   return (
//     <StyledContainer>
//       <Header />
//       <StyledContent>
//         <Outlet />
//       </StyledContent>
//       <Footer />
//     </StyledContainer>
//   );
// }

// export default Layout;

import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 98vh;
`;

const StyledContent = styled.div`
  flex: 1; /* Allow the content area to grow to fill available space */
`;

function Layout() {
  return (
    <StyledContainer>
      <Header />
      <StyledContent>
        <Outlet /> {/* Render the content of the current route */}
      </StyledContent>
      {/* Conditionally render Footer */}
      <Footer />
    </StyledContainer>
  );
}

export default Layout;
