// import React, { useEffect, useState } from "react";
// import ReactDOM from "react-dom";
// import "./index.css";
// import App from "./App";
// import { BrowserRouter as Router } from "react-router-dom";
// import PageLoader from "./Components/PageLoader/PageLoader2";
// import "./styles.scss";

// // Function to fetch the user data and store only the relevant data in sessionStorage
// const fetchUserData = async (formattedUsername) => {
//   try {
//     const response = await fetch(
//       "https://ventral.no/legenettside_backend/wp-json/custom/v1/users"
//     );
//     const data = await response.json();

//     // Find the current user's data based on the domain (formatted username)
//     const user = data.find((user) => user.name === formattedUsername);

//     // If the user exists and has clinic data, store only that data in sessionStorage
//     if (user && user.acf && user.acf.clinicdata) {
//       sessionStorage.setItem("userData", JSON.stringify(user));
//       console.log("User data fetched and stored:", user.acf.clinicdata);
//     } else {
//       console.error("No clinic data found for this user.");
//     }

//     // Always set the "administrator" user data to sessionStorage as "maler"
//     const adminUser = data.find((user) => user.name === "Administrator");
//     if (adminUser && adminUser.acf && adminUser.acf) {
//       sessionStorage.setItem("maler", JSON.stringify(adminUser.acf)); // Set admin clinic data as "maler"
//     } else {
//       console.error("No clinic data found for the administrator.");
//     }
//   } catch (error) {
//     console.error("Failed to fetch user data:", error);
//   }
// };

// const Root = () => {
//   const [isLoaded, setIsLoaded] = useState(false);

//   useEffect(() => {
//     const domain = window.location.hostname; // e.g., "website1.com"
//     // Remove 'www.' if it exists, and keep only the domain name
//     const cleanDomain = domain.replace(/^www\./, "");

//     // Extract only letters and numbers from the domain, e.g., 'website1com'
//     const formattedUsername = cleanDomain.replace(/\W+/g, "");
//     console.log(formattedUsername); // "website1com"

//     // Fetch the user data and wait for it to complete before rendering
//     const fetchData = async () => {
//       await fetchUserData(formattedUsername); // Pass the formatted username to the function
//       setIsLoaded(true); // Set isLoaded to true once data is fetched and stored
//     };

//     fetchData();
//   }, []);

//   if (!isLoaded) {
//     // Optionally render a loading spinner or message while the data is loading
//     return <PageLoader />;
//   }

//   return (
//     <Router>
//       <App />
//     </Router>
//   );
// };

// // Render the app only after the data is fetched
// ReactDOM.createRoot(document.getElementById("root")).render(
//   <React.StrictMode>
//     <Root />
//   </React.StrictMode>
// );

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import PageLoader from "./Components/PageLoader/PageLoader2";
import "./styles.scss";

// Function to fetch the user data and store only the relevant data in sessionStorage
const fetchUserData = async (formattedUsername) => {
  try {
    const response = await fetch(
      "https://ventral.no/legenettside_backend/wp-json/custom/v1/users"
    );
    const data = await response.json();

    // Find the current user's data based on the domain (formatted username)
    const user = data.find((user) => user.name === formattedUsername);

    // If the user exists and has clinic data, store only that data in sessionStorage
    if (user && user.acf && user.acf.clinicdata) {
      sessionStorage.setItem("userData", JSON.stringify(user));
      // Dynamically set title and favicon based on the clinic data
      setPageMetadata(
        JSON.parse(user.acf.clinicdata).doctorsOffice.officeName,
        JSON.parse(user.acf.clinicdata).doctorsOffice.logoImage
      );

      console.log(
        "Title and favicon set dynamically.",
        JSON.parse(user.acf.clinicdata).doctorsOffice.officeName
      );
    } else {
      console.error("No clinic data found for this user.");
    }

    // Always set the "administrator" user data to sessionStorage as "maler"
    const adminUser = data.find((user) => user.name === "Administrator");
    if (adminUser && adminUser.acf && adminUser.acf) {
      sessionStorage.setItem("maler", JSON.stringify(adminUser.acf)); // Set admin clinic data as "maler"
    } else {
      console.error("No clinic data found for the administrator.");
    }
  } catch (error) {
    console.error("Failed to fetch user data:", error);
  }
};

// Function to dynamically set the title and favicon
const setPageMetadata = (title, faviconUrl) => {
  // Set the page title dynamically
  document.title = title;

  // Find or create the <link rel="icon"> element
  let link = document.querySelector("link[rel='icon']");

  if (!link) {
    // If there's no <link rel="icon"> tag, create one
    link = document.createElement("link");
    link.rel = "icon";
    document.head.appendChild(link);
  }

  // Set the favicon dynamically
  link.href = faviconUrl;
};

const Root = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const domain = window.location.hostname; // e.g., "website1.com"
    // Remove 'www.' if it exists, and keep only the domain name
    const cleanDomain = domain.replace(/^www\./, "");

    // Extract only letters and numbers from the domain, e.g., 'website1com'
    const formattedUsername = cleanDomain.replace(/\W+/g, "");
    console.log(formattedUsername); // "website1com"

    // Fetch the user data and wait for it to complete before rendering
    const fetchData = async () => {
      await fetchUserData(formattedUsername); // Pass the formatted username to the function
      setIsLoaded(true); // Set isLoaded to true once data is fetched and stored
    };

    fetchData();
  }, []);

  if (!isLoaded) {
    // Optionally render a loading spinner or message while the data is loading
    return <PageLoader />;
  }

  return (
    <Router>
      <App />
    </Router>
  );
};

// Render the app only after the data is fetched
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
