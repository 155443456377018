import { NavLink } from "react-router-dom";
import styled from "styled-components";

const FooterLinks = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 3.5rem auto;
  gap: 3rem;
  flex-wrap: wrap;
  width: 80%;

  @media (max-width: 599px) {
    flex-direction: column;
    align-items: center;
    font-size: 1.3rem;
  }

  a {
    text-decoration: none;
    font-size: 1.1rem;

    &.active {
      font-family: "NunitoSansMedium", sans-serif;
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

// Navigation links
const navLinks = [
  { label: "Hjem", to: "/" },
  { label: "Kontakt", to: "/kontakt" },
  { label: "Ansatte", to: "/ansatte" },
  { label: "Priser", to: "/priser" },
  { label: "Info", to: "/informasjon" },
];

const Navlinks = () => {
  return (
    <FooterLinks>
      {navLinks.map((link, index) => (
        <NavLink
          key={index}
          to={link.to}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          {link.label}
        </NavLink>
      ))}
    </FooterLinks>
  );
};

export default Navlinks;
